import {
  WidgetAttributeDTO,
  WidgetAttributeDTOTypeEnum,
  WidgetDTO,
} from '@neptune/shared/core-apis-leaderboard-domain';
import {
  type AttributePatternWidgetSource,
  type AttributeWidgetSource,
  type CustomYExpressionWidgetSource,
  isAttributePatternSource,
  isYCustomExpressionSource,
  type NamespaceWidgetSource,
  type Widget,
  WidgetSourceType,
} from '@neptune/shared/widgets-domain';

import { AttributeDefinition, AttributeType } from 'domain/experiment/attribute';
import { extractAttributeDefinitions } from 'domain/widget/widget-model-utils';

import {
  convertWidgetOptionsFromApiToDomain,
  convertWidgetOptionsFromDomainToApi,
} from './core/domain/widget-registry';
import { widgetTypeFromApiToDomain, widgetTypeFromDomainToApi } from './core/widget';

export abstract class WidgetModelConverter {
  static widgetFromApiToDomain(widget: WidgetDTO): Widget {
    const attributes = widget.attributes?.map(WidgetModelConverter.widgetAttributeFromApiToDomain);

    const attributeSources: AttributeWidgetSource[] = (attributes ?? []).map(
      ({ type, name, subproperty }) => ({
        type: WidgetSourceType.ATTRIBUTE,
        value: name,
        metadata: { attributeType: type, subproperty },
      }),
    );

    const namespaceSources: NamespaceWidgetSource[] = (widget.namespaces ?? []).map(
      (namespace) => ({
        type: WidgetSourceType.NAMESPACE,
        value: namespace,
      }),
    );

    const customYExpressionsSources: CustomYExpressionWidgetSource[] = extractCustomYExpressions(
      widget.options,
    ).map(({ expression, alias }) => ({
      type: WidgetSourceType.CUSTOM_Y_EXPRESSION,
      value: expression,
      metadata: { alias },
    }));

    const attributePatternSources: AttributePatternWidgetSource[] = (
      widget.attributeNameMustMatchRegexes ?? []
    ).map((attributePattern) => ({
      type: WidgetSourceType.ATTRIBUTE_PATTERN,
      value: attributePattern,
    }));

    return {
      id: widget.id,
      name: widget.name,
      type: widgetTypeFromApiToDomain(widget.type),
      options: convertWidgetOptionsFromApiToDomain(widget.type, widget.options),
      sources: [
        ...attributeSources,
        ...namespaceSources,
        ...customYExpressionsSources,
        ...attributePatternSources,
      ],
    };
  }

  static widgetAttributeFromApiToDomain(attribute: WidgetAttributeDTO): AttributeDefinition {
    return {
      name: attribute.name,
      subproperty: attribute.subproperty,
      type: WidgetModelConverter.widgetAttributeTypeFromApiToDomain(attribute.type),
    };
  }

  static widgetFromDomainToApi(widget: Widget): WidgetDTO | undefined {
    const type = widgetTypeFromDomainToApi(widget.type);

    if (!type) {
      return undefined;
    }

    const yCustomExpressions = internalExtractYCustomExpressions(widget);

    const namespaces = internalExtractNamespaces(widget);

    const attributeNameMustMatchRegexes = internalExtractMustMatchAttributePatterns(widget);

    const rawOptions = convertWidgetOptionsFromDomainToApi(widget.type, widget.options);
    const options = yCustomExpressions.length > 0 ? { ...rawOptions } : rawOptions;

    // isRecord makes typescript happy
    if (yCustomExpressions.length > 0 && isRecord(options)) {
      options.yCustomExpressions = yCustomExpressions;
    }

    const attributes = extractAttributeDefinitions(widget).map(
      WidgetModelConverter.widgetAttributeFromDomainToApi,
    );

    return {
      id: widget.id,
      attributes,
      name: widget.name,
      attributeNameMustMatchRegexes,
      namespaces,
      options,
      type,
    };
  }

  static widgetAttributeFromDomainToApi(
    attributeDefinition: AttributeDefinition,
  ): WidgetAttributeDTO {
    return {
      ...attributeDefinition,
      type: WidgetModelConverter.widgetAttributeTypeFromDomainToApi(attributeDefinition.type),
    };
  }

  static widgetAttributeTypeFromDomainToApi(
    attributeType: AttributeType,
  ): WidgetAttributeDTOTypeEnum {
    switch (attributeType) {
      case 'bool':
        return WidgetAttributeDTOTypeEnum.Bool;

      case 'datetime':
        return WidgetAttributeDTOTypeEnum.Datetime;

      case 'experimentState':
        return WidgetAttributeDTOTypeEnum.ExperimentState;

      case 'file':
        return WidgetAttributeDTOTypeEnum.File;

      case 'fileSet':
        return WidgetAttributeDTOTypeEnum.FileSet;

      case 'float':
        return WidgetAttributeDTOTypeEnum.Float;

      case 'floatSeries':
        return WidgetAttributeDTOTypeEnum.FloatSeries;

      case 'gitRef':
        return WidgetAttributeDTOTypeEnum.GitRef;

      case 'imageSeries':
        return WidgetAttributeDTOTypeEnum.ImageSeries;

      case 'int':
        return WidgetAttributeDTOTypeEnum.Int;

      case 'string':
        return WidgetAttributeDTOTypeEnum.String;

      case 'stringSeries':
        return WidgetAttributeDTOTypeEnum.StringSeries;

      case 'stringSet':
        return WidgetAttributeDTOTypeEnum.StringSet;
    }

    return WidgetAttributeDTOTypeEnum.NotSupported;
  }

  static widgetAttributeTypeFromApiToDomain(
    attributeType: WidgetAttributeDTOTypeEnum,
  ): AttributeType {
    switch (attributeType) {
      case WidgetAttributeDTOTypeEnum.Bool:
        return 'bool';

      case WidgetAttributeDTOTypeEnum.Datetime:
        return 'datetime';

      case WidgetAttributeDTOTypeEnum.ExperimentState:
        return 'experimentState';

      case WidgetAttributeDTOTypeEnum.File:
        return 'file';

      case WidgetAttributeDTOTypeEnum.FileSet:
        return 'fileSet';

      case WidgetAttributeDTOTypeEnum.Float:
        return 'float';

      case WidgetAttributeDTOTypeEnum.FloatSeries:
        return 'floatSeries';

      case WidgetAttributeDTOTypeEnum.GitRef:
        return 'gitRef';

      case WidgetAttributeDTOTypeEnum.ImageSeries:
        return 'imageSeries';

      case WidgetAttributeDTOTypeEnum.Int:
        return 'int';

      case WidgetAttributeDTOTypeEnum.String:
        return 'string';

      case WidgetAttributeDTOTypeEnum.StringSeries:
        return 'stringSeries';

      case WidgetAttributeDTOTypeEnum.StringSet:
        return 'stringSet';
    }

    return 'notSupported';
  }
}

function isRecord(input: unknown): input is Record<string, unknown> {
  return typeof input === 'object' && input !== null;
}

function internalExtractYCustomExpressions(widget: Widget) {
  return widget.sources
    .filter(isYCustomExpressionSource)
    .map((source) => ({ expression: source.value, alias: source.metadata.alias }));
}

function internalExtractNamespaces(widget: Widget) {
  return widget.sources
    .filter((source) => source.type === WidgetSourceType.NAMESPACE)
    .map((source) => source.value);
}

function internalExtractMustMatchAttributePatterns(widget: Widget) {
  return widget.sources.filter(isAttributePatternSource).map((source) => source.value);
}

function extractCustomYExpressions(
  options?: Record<string, any>,
): { alias?: string; expression: string }[] {
  if (!options) {
    return [];
  }

  // temporary store until BE starts recognizing widget sources
  if (
    options.yCustomExpressions &&
    Array.isArray(options.yCustomExpressions) &&
    options.yCustomExpressions.every(
      (expressionEntry) =>
        typeof expressionEntry === 'object' &&
        typeof expressionEntry?.expression === 'string' &&
        (expressionEntry?.alias === undefined || typeof expressionEntry?.alias === 'string'),
    )
  ) {
    return [...options.yCustomExpressions];
  }

  // backward compatible with old code
  // todo remove me once BE migration is done
  if (options.yCustomExpression && typeof options.yCustomExpression === 'string') {
    return [{ expression: options.yCustomExpression }];
  }

  return [];
}
