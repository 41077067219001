import React from 'react';

import { AddWidgetModalContainerProps } from './add-widget-modal-container-props';
import { WidgetType } from './core-widget-types';
import { WidgetContainerProps } from './widget-container-props';
import { WidgetDefinitionNew } from './widget-definition-new';

export type WidgetRepositoryContext = {
  metadataList: WidgetDefinitionNew[];
  configuringComponents: Partial<
    Record<WidgetType, React.ComponentType<AddWidgetModalContainerProps>>
  >;
  renderingComponents: Partial<Record<WidgetType, React.ComponentType<WidgetContainerProps>>>;
};

export const widgetRepositoryContext = React.createContext<WidgetRepositoryContext | null>(null);

export function WidgetRepositoryProvider({
  config,
  children,
}: {
  children: React.ReactNode;
  config: {
    configuringComponent?: React.ComponentType<AddWidgetModalContainerProps>;
    metadata: WidgetDefinitionNew;
    // Section Widget rendering component is handled in a special way outside of WidgetRepository
    renderingComponent?: React.ComponentType<WidgetContainerProps>;
  }[];
}) {
  const value: WidgetRepositoryContext = React.useMemo(
    () => ({
      metadataList: config.map(({ metadata }) => metadata),
      configuringComponents: Object.fromEntries(
        config
          .filter(({ configuringComponent }) => !!configuringComponent)
          .map(({ metadata, configuringComponent }) => [metadata.widgetType, configuringComponent]),
      ),
      renderingComponents: Object.fromEntries(
        config
          .filter(({ renderingComponent }) => !!renderingComponent)
          .map(({ metadata, renderingComponent }) => [metadata.widgetType, renderingComponent]),
      ),
    }),
    [config],
  );

  return React.createElement(widgetRepositoryContext.Provider, { value }, children);
}
