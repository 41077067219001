import React from 'react';
import { useSelector } from 'react-redux';

import { useLocalModal } from '@neptune/shared/common-util';
import { isNeptuneAdmin } from '@neptune/shared/core-super-reader-business-logic';
import { SidebarActionItem } from '@neptune/shared/navigation-ui';

import { isOnPremiseDeployment } from 'common/deploymentModes';
import { getLicense } from 'state/license/selectors';

import { LicenseStatusModalContainer } from './LicenseStatusModalContainer';

export const LicenseStatusInfoContainer: React.FC = () => {
  const { isOpen, open, close } = useLocalModal();

  const neptuneAdmin = useSelector(isNeptuneAdmin);
  const expirationDate = useSelector(getLicense).expirationDate;

  if (!isOnPremiseDeployment()) {
    return null;
  }

  if (!neptuneAdmin) {
    return null;
  }

  if (!expirationDate) {
    return null;
  }

  return (
    <>
      <LicenseStatusModalContainer expirationDate={expirationDate} isOpen={isOpen} close={close} />
      <SidebarActionItem
        size="xs"
        onToggle={open}
        label="License info"
        icon={{ glyph: 'key', size: 'lg' }}
      />
    </>
  );
};
