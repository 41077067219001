import {
  isAttributePatternSource,
  isAttributeSource,
  isYCustomExpressionSource,
  type Widget,
  type WidgetSource,
  WidgetSourceType,
} from '@neptune/shared/widgets-domain';

import { AttributeDefinition } from 'domain/experiment/attribute';

// @todo attribute-pattern we should handle multiple attribute patterns in the future
export function sourcesToAttributePattern(sources: WidgetSource[]): string | undefined {
  return sources.find(isAttributePatternSource)?.value;
}

export function inferWidgetName(widget: Widget): string {
  if (widget.name) {
    return widget.name;
  }

  if (widget.sources?.length) {
    const namespace = widget.sources
      ?.filter((source) => source.type === WidgetSourceType.NAMESPACE)
      .map((source) => source.value);

    const attributes = widget.sources?.filter(isAttributeSource).map((source) => source.value);

    const customExpressions = widget.sources
      ?.filter(isYCustomExpressionSource)
      .map((source) => source.metadata.alias);

    const attributePattern = sourcesToAttributePattern(widget.sources);

    const result = [];

    if (namespace?.length) {
      result.push(namespace.join(', '));
    }

    if (attributes?.length) {
      result.push(attributes.join(', '));
    }

    if (customExpressions?.length) {
      result.push(customExpressions.join(', '));
    }

    if (attributePattern) {
      result.push(attributePattern);
    }

    return result.join(', ');
  }

  return 'Unnamed';
}

export function extractAttributeDefinitions(widget: Widget): AttributeDefinition[] {
  return widget.sources
    .filter(isAttributeSource)
    .map(
      (source): Partial<AttributeDefinition> => ({
        name: source.value,
        type: source.metadata?.attributeType,
        ...(source.metadata?.subproperty && { subproperty: source.metadata.subproperty }),
      }),
    )
    .filter(
      (definition): definition is AttributeDefinition => !!definition.name && !!definition.type,
    );
}

export function attributeDefinitionToWidgetSource(
  attributeDefinition: AttributeDefinition,
): WidgetSource {
  return {
    type: WidgetSourceType.ATTRIBUTE,
    value: attributeDefinition.name,
    metadata: {
      attributeType: attributeDefinition.type,
      ...(attributeDefinition.subproperty && { subproperty: attributeDefinition.subproperty }),
    },
  };
}
