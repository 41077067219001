import { NewLayoutPlacement } from '@neptune/shared/grid-layout-domain';

import type { Widget, WidgetType } from './core-widget-types';

export const ADD_WIDGET_MODAL_NAME = 'ADD_WIDGET_MODAL';

export type AddWidgetModalData = {
  widget?: Widget;
  widgetPlacement?: NewLayoutPlacement;
  widgetType?: WidgetType;
};
