export const featureFlags = [
  'withAdminPanel',

  'withRunGroupsV4',

  'withSingleRunAutoFollow',
  'withSectionsInDashboards',

  'withCompoundSearch',
  'withCompoundSearchWithEditableCriterionItems',
  'withNewChartConfig',
  'withImprovedMultiRunSeriesVisibility',
  'withDynamicRegexpMetrics',
] as const;
export type FeatureFlag = (typeof featureFlags)[number];

export type FeatureFlagConfig = `${FeatureFlag}Config`;
