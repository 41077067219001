import {
  LayoutRect,
  SectionLayoutDetails,
  SectionsLayoutMap,
} from '@neptune/shared/grid-layout-domain';

export function collectSectionLayoutsDetails<ItemLayoutT extends LayoutRect>({
  unfoldedGridLayouts,
  isSection,
  isFoldedSection,
  getItemKey,
  getDefaultHeight,
}: {
  unfoldedGridLayouts: ItemLayoutT[];
  isSection: (key: string) => boolean;
  isFoldedSection: (key: string) => boolean;
  getItemKey: (item: ItemLayoutT) => string;
  getDefaultHeight: (layout: ItemLayoutT) => number;
}): SectionsLayoutMap {
  const sectionsMap: SectionsLayoutMap = {};

  let currentSectionDetails: SectionLayoutDetails | undefined = undefined;

  for (let idx = 0; idx < unfoldedGridLayouts.length; ++idx) {
    const layout = unfoldedGridLayouts[idx];
    const layoutKey = getItemKey(layout);

    if (isSection(layoutKey)) {
      currentSectionDetails = sectionsMap[layoutKey] = {
        firstItemUnfoldedY: layout.y + layout.h,
        contentsRowHeight: 0,
        singleColumnContentsRowHeight: 0,
        firstItemIndex: idx + 1,
        endIndex: idx + 1,
        itemCount: 0,
        folded: isFoldedSection(layoutKey),
      };
    } else if (currentSectionDetails) {
      currentSectionDetails.itemCount += 1;
      currentSectionDetails.endIndex += 1;
      currentSectionDetails.contentsRowHeight = Math.max(
        currentSectionDetails.contentsRowHeight,
        layout.y + layout.h - currentSectionDetails.firstItemUnfoldedY,
      );
      currentSectionDetails.singleColumnContentsRowHeight += getDefaultHeight(layout);
    }
  }

  return sectionsMap;
}
