import type { Widget } from '@neptune/shared/widgets-domain';

import { WidgetDefinition } from '../core/domain/widget-definition';

export function isFileWidget(widget: Widget): boolean {
  return widget.type === 'file';
}

export const fileWidgetDefinition: WidgetDefinition = {
  type: 'file',
};
