import type { Widget } from '@neptune/shared/widgets-domain';

import { WidgetDefinition } from '../core/domain/widget-definition';

export function isInteractiveTableWidget(widget: Widget): boolean {
  return widget.type === 'interactiveTable';
}

export const interactiveTableWidgetDefinition: WidgetDefinition = {
  type: 'interactiveTable',
};
