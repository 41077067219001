import React from 'react';

import { DropdownToggleInterface } from '@neptune/shared/venus-domain';

import type { Widget } from './core-widget-types';

type WidgetRendererContext = {
  checkbox?: React.ComponentType<{ widget: Widget }>;
  exportMenu?: React.ComponentType<{
    widget: Widget;
    toggle?: React.ReactElement<DropdownToggleInterface>;
    onSelect?: () => void;
  }>;
};

export const WidgetRendererContext = React.createContext<WidgetRendererContext>({});
