import type { Widget } from '@neptune/shared/widgets-domain';

import { WidgetDefinition } from '../core/domain/widget-definition';

export type ImageWidget = Widget & {
  type: 'image';
  options: ImageWidgetOptions;
};
export type ImageComparisonWidget = Widget & {
  type: 'imageComparison';
  options: ImageComparisonWidgetOptions;
};
export type ImageWidgetOptions = {
  alignment: ImageAlignment;
  size: ImageSize;
};
export type ImageComparisonWidgetOptions = ImageWidgetOptions;
export type ImageAlignment = 'center' | 'top-left';
export type ImageSize = 'actual' | 'aspect-fit' | 'stretch-to-fit';

export function isImageWidget(widget: Widget): widget is ImageWidget {
  return widget.type === 'image';
}

export function isImageComparisonWidget(widget: Widget): widget is ImageComparisonWidget {
  return widget.type === 'imageComparison';
}

export function imageWidgetOptionsFromApiToDomain(options?: Record<string, any>) {
  return {
    alignment: options?.alignment,
    size: options?.size,
  };
}

export const imageWidgetDefinition: WidgetDefinition = {
  type: 'image',
  fromApiToDomain: imageWidgetOptionsFromApiToDomain,
};

export const imageComparisonWidgetDefinition: WidgetDefinition = {
  type: 'imageComparison',
  fromApiToDomain: imageWidgetOptionsFromApiToDomain,
};
