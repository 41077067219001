import { DashboardConfigDTO } from 'generated/leaderboard-client';

import { AttributeDefinitionConverter } from 'domain/experiment/attribute';
import { ChartGlobalConfig } from 'domain/widget/support/chart-widget-global-configuration';

export type DashboardConfig = {
  smoothing?: number;
  chartGlobalConfig?: ChartGlobalConfig;
};

type XAxisMode = 'step' | 'relativeTime' | 'absoluteTime' | 'timeSeries';
type AxisScale = 'linear' | 'logarithmic';

export const createDashboardConfigModelConverter = () => {
  function fromApiToDomain(config: DashboardConfigDTO | undefined): DashboardConfig | undefined {
    if (!config) {
      return undefined;
    }

    const {
      metricsStepsRange,
      xaxisRange,
      xaxisScale: xAxisScale,
      yaxisScale: yAxisScale,
      xaxisMode: xAxisMode,
      xaxisMetric: xAxisMetric,
      smoothing,
    } = config;

    return {
      chartGlobalConfig: {
        metricsStepsRange: metricsStepsRange
          ? [metricsStepsRange.from, metricsStepsRange.to]
          : undefined,
        smoothing,
        xAxisMode: xAxisMode as XAxisMode,
        xAxisMetric:
          xAxisMetric &&
          AttributeDefinitionConverter.attributeDefinitionFromApiToDomain(xAxisMetric),
        xAxisScale: xAxisScale as AxisScale,
        yAxisScale: yAxisScale as AxisScale,
        xAxisRange: xaxisRange ? [xaxisRange.from, xaxisRange.to] : undefined,
      },
    };
  }

  function fromDomainToApi(config: DashboardConfig | undefined): DashboardConfigDTO | undefined {
    if (!config) {
      return undefined;
    }

    const { chartGlobalConfig } = config;

    const {
      xAxisScale,
      yAxisScale,
      xAxisMode,
      xAxisMetric,
      xAxisRange,
      metricsStepsRange,
      smoothing,
    } = chartGlobalConfig ?? {};

    return {
      metricsStepsRange: {
        from: metricsStepsRange?.[0],
        to: metricsStepsRange?.[1],
      },
      xaxisRange: {
        from: xAxisRange?.[0],
        to: xAxisRange?.[1],
      },
      xaxisScale: xAxisScale,
      yaxisScale: yAxisScale,
      xaxisMode: xAxisMode,
      xaxisMetric:
        xAxisMetric && AttributeDefinitionConverter.attributeDefinitionFromDomainToApi(xAxisMetric),
      smoothing,
    };
  }

  return {
    fromDomainToApi,
    fromApiToDomain,
  };
};

export const DashboardConfigModelConverter = createDashboardConfigModelConverter();
