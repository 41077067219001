import type { Widget } from '@neptune/shared/widgets-domain';

import { WidgetDefinition } from '../core/domain/widget-definition';

export function isFileSetWidget(widget: Widget): boolean {
  return widget.type === 'fileSet';
}

export const fileSetWidgetDefinition: WidgetDefinition = {
  type: 'fileSet',
};
