import React from 'react';
import { noop } from 'lodash';

export type BulkOperationsContext<T extends { id: string }> = {
  onToggle: (widget: T) => void;
  onAdd: (widget: T) => void;
  onRemove: (id: string) => void;
  selection: Map<string, T>;
  onReset: () => void;
};

export const BulkOperationsContext = React.createContext<BulkOperationsContext<any>>({
  selection: new Map<string, any>(),
  onRemove: noop,
  onToggle: noop,
  onReset: noop,
  onAdd: noop,
});
