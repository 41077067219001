import { sortBy } from 'lodash';

import { LayoutMinDimensions, LayoutRect } from '@neptune/shared/grid-layout-domain';

type LayoutForSingleColumnGeneration = LayoutRect &
  Partial<LayoutMinDimensions> & { static?: boolean };

export function generateSingleColumnLayouts<ItemLayoutT extends LayoutForSingleColumnGeneration>(
  sourceLayouts: ItemLayoutT[],
  {
    getDefaultHeight,
    layoutColumns = 1,
    isStatic = true,
  }: {
    getDefaultHeight: (layout: ItemLayoutT) => number;
    layoutColumns?: number;
    isStatic?: boolean;
  },
): ItemLayoutT[] {
  // Avoid edge case in code below.
  if (!sourceLayouts.length) {
    return [];
  }

  // Sorting by h !== 0 is to ensure correct placement of folded layouts in section.
  // They should be placed at the top of the section, regardless of possibly having
  // larger x value than the next widget (in practice the next widget would be
  // always another section header with x = 0).
  const sorted = sortBy(sourceLayouts, 'y', ({ h }) => h !== 0, 'x');
  const result: ItemLayoutT[] = [];

  let nextY = 0;

  for (const layout of sorted) {
    // Contents of a folded section are rendered with h === 0.
    // We need to keep them in this state.
    const h = layout.h && getDefaultHeight(layout);

    result.push({
      ...layout,
      x: 0,
      y: nextY,
      w: layoutColumns,
      h,
      // Make sure that constraints don't clash with actual dimensions,
      // especially width.
      minW: layout.minW && Math.min(layout.minW, layoutColumns),
      minH: layout.minH && Math.min(layout.minH, h),
      static: isStatic,
    });

    nextY += h;
  }

  return result;
}
