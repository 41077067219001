import { isArray } from 'lodash';

import {
  AttributeFilterDTO,
  AttributeTypeDTO,
  leaderboardClient,
} from '@neptune/shared/core-apis-leaderboard-domain';

import {
  AttributeDefinition,
  AttributeDefinitionConverter,
  AttributeType,
} from 'domain/experiment/attribute';

type QueryAttributeDefinitionsFilter = Partial<Record<AttributeType, {}>>;

type QueryAttributeDefinitionsRequest = {
  filterAttributeTypes?: AttributeType[] | QueryAttributeDefinitionsFilter;
  attributeNameFilter?: {
    mustMatchRegexes?: string[];
    mustNotMatchRegexes?: string[];
  };
  limit?: number;
  nextPageToken?: string;
  experimentIds?: string[];
  projectIdentifiers: string[];
};

type QueryAttributeDefinitionsResult = {
  attributeDefinitions: AttributeDefinition[];
  priorityMatches: Map<string, boolean>;
  thereIsMore: boolean;
};

/**
 * Queries attribute definitions prioritized by the given experimentIds
 *
 * ⚠️ WARNING: This function has a high performance cost. Use it with caution.
 * It was designed with the intention of being used in a fields picker context.
 *
 */
export async function queryAttributeDefinitionsPrioritized({
  filterAttributeTypes,
  limit,
  projectIdentifiers,
  attributeNameFilter,
  experimentIds,
}: QueryAttributeDefinitionsRequest): Promise<QueryAttributeDefinitionsResult> {
  const attributeFilter = isArray(filterAttributeTypes)
    ? convertAttributeTypeArrayToApiAttributeFilter(filterAttributeTypes)
    : convertAttributeFilterFromDomainToApi(filterAttributeTypes);

  if (attributeFilter?.length === 0 && lengthOfFilterAttributeTypes(filterAttributeTypes) !== 0) {
    // If this happened, it means that we have desynchronized FE and BE applications.
    throw new Error(
      'Requested attribute type filter, but all requested types are not supported, which may cause filtering to be disabled',
    );
  }

  const result = await leaderboardClient.queryAttributeDefinitionsPrioritized({
    query: {
      filterQueryAttributeDefinitionsDTO: {
        attributeFilter,
        attributeNameFilter,
      },
      limit,
      priorityQueryAttributeDefinitionsDTO: {
        experimentIds,
      },
      projectIdentifiers,
    },
  });

  const priorityMatches: Map<string, boolean> = new Map();

  for (const attr of result.entries) {
    priorityMatches.set(attr.attributeDefinitionDTO.name, !!attr.matchedPriorityQuery);
  }

  return {
    attributeDefinitions: result.entries.map(({ attributeDefinitionDTO }) => {
      return AttributeDefinitionConverter.attributeDefinitionFromApiToDomain(
        attributeDefinitionDTO,
      );
    }),
    priorityMatches,
    thereIsMore: result.hasMore,
  };
}

function convertAttributeFilterFromDomainToApi(
  input: QueryAttributeDefinitionsFilter | undefined,
): AttributeFilterDTO[] | undefined {
  if (!input) {
    return;
  }

  return Object.entries(input)
    .map(([domainAttributeType]): AttributeFilterDTO | undefined => {
      const attributeType = AttributeDefinitionConverter.attributeTypeToApi(domainAttributeType);

      if (!attributeType) {
        return;
      }

      return { attributeType };
    })
    .filter((input: AttributeFilterDTO | undefined): input is AttributeFilterDTO => !!input);
}

function convertAttributeTypeArrayToApiAttributeFilter(
  input: AttributeType[],
): AttributeFilterDTO[] {
  return input
    .map(AttributeDefinitionConverter.attributeTypeToApi)
    .filter((attributeType): attributeType is AttributeTypeDTO => !!attributeType)
    .map((attributeType) => ({ attributeType }));
}

function lengthOfFilterAttributeTypes(
  input: AttributeType[] | QueryAttributeDefinitionsFilter | undefined,
): number {
  if (!input) {
    return 0;
  }

  if (isArray(input)) {
    return input.length;
  }

  return Object.keys(input).length;
}
