import { DoneFn, SimpleState } from 'router5/types/types/base';
import { MiddlewareFactory, Router } from 'router5/types/types/router';

import { RouterState } from '@neptune/shared/routing-domain';

import { getOrganizationsList } from 'state/selectors';

export const caseInsensitiveWorkspaceInUrlMW: MiddlewareFactory =
  (router: Router, dependencies) =>
  (toState: RouterState, fromState: RouterState | null, done: DoneFn) => {
    // we want to run this logic only if it is direct URL navigation
    if (fromState === null) {
      const { params } = toState;

      if (params == null) {
        done();
        return;
      }

      const { organizationName } = params;

      if (organizationName != null) {
        // verify in app state the real name of the organization
        const store = dependencies.store;
        const organization = getOrganizationsList(store.getState()).find(
          (org: { name: string }) => org.name.toLowerCase() === organizationName.toLowerCase(),
        );

        if (organization != null && organization.name !== organizationName) {
          const newState: SimpleState = {
            name: toState.name,
            params: {
              ...toState.params,
              organizationName: organization?.name,
            },
          };

          return Promise.reject({ redirect: newState });
        }

        done();
        return;
      }
    }

    done();
  };
